/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ParentBaseEvent from '../events/BaseEvent.event';
import ProductHelper from '../GA4_utils';
// eslint-disable-next-line import/no-cycle
import { COLOR_ATTRIBUTE, GENDER_ATTRIBUTE, SIZE_ATTRIBUTE } from '../GA4_utils/Product';
import { EVENT_VIEW_ITEM_LIST_GA4 } from '../GoogleTagManager.config';

export const GENERAL_DATALAYER_CHECKER_INTERVAL = 100;

/**
 * Base event for GTM PWA events
 */
export class BaseEventEvent extends ParentBaseEvent {
    /**
     * Manages pushing the event after the general
     */
    pushAfterGeneralEvent(passedFunction = () => {}) {
        const { gtmIsGeneralEventPushed, gtm4IsGeneralEventPushed } = window;

        if (gtmIsGeneralEventPushed && gtm4IsGeneralEventPushed) {
            passedFunction();
            return;
        }

        const interval = setInterval(() => {
            const { gtmIsGeneralEventPushed, gtm4IsGeneralEventPushed } = window;

            if (gtmIsGeneralEventPushed && gtm4IsGeneralEventPushed) {
                clearInterval(interval);
                passedFunction();
            }
        }, GENERAL_DATALAYER_CHECKER_INTERVAL);
    }

    /**
     * Prepare cart data
     *
     * @param itemsProp
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, category: string, brand: string}[]}
     */
    prepareCartData(itemsProp = null) {
        const {
            total_quantity = 0,
            items: cartItems = [],
            groupedProducts = {}
        } = this.getCartProductData();

        const items = itemsProp || cartItems;

        const itemsData = items
            .map((item) => ({
                ...ProductHelper.getItemData(item),
                quantity: ProductHelper.getQuantity(item)
            }));

        if (total_quantity !== 0) {
            Object.values(groupedProducts || {}).forEach(({ data }) => itemsData.push(data));
        }

        return itemsData;
    }

    /**
     * Get data from impression
     *
     * @param product
     * @param addPosition
     * @param noIndex
     */
    getItemListData(product, addPosition = false, noIndex = false) {
        const data = this.getProductFromImpression(product) || {};

        if (Object.keys(data).length) {
            const {
                index = 0,
                item_list_id = '',
                item_list_name = ''
            } = data;

            if (addPosition) {
                const { item_list_position } = data;

                return {
                    index, item_list_id, item_list_name, item_list_position
                };
            }

            if (noIndex) {
                return {
                    item_list_id, item_list_name
                };
            }

            return {
                index, item_list_id, item_list_name
            };
        }

        return {};
    }

    /**
     * Get product position in impression
     *
     * @return {*}
     * @param clickedProduct
     */
    getProductFromImpression(clickedProduct) {
        const { impressions_ga4 = [] } = this.getStorage(EVENT_VIEW_ITEM_LIST_GA4);
        const id = ProductHelper.getSku(clickedProduct);
        const { sku } = clickedProduct;

        return impressions_ga4.find(({ item_id: impressionId }) => (
            impressionId === id || impressionId === sku
        ));
    }

    /**
     * Get cart sub total
     *
     * @return {string}
     */
    getItemsTotal() {
        return this.getAppState().CartReducer.cartTotals?.prices?.grand_total?.value || 0;
    }

    /**
     * Get cart items with 'index', 'item_list_id' and 'item_list_name'
     * attributes if available
     *
     * @param itemsProp
     * @param isPurchaseEvent
     * @returns {*}
     */
    getCartItemsWithListData(itemsProp = null, isPurchaseEvent = false, isGA4 = false, totals = null, couponCode = null) {
        const items = itemsProp || this.getCartProductData()?.items || {};

        return items.map(
            (item) => {
                const {
                    sku,
                    product,
                    product: {
                        id: configurableID,
                        variants = [],
                        configurableVariantIndex = ProductHelper.getSelectedVariantIndex(product, sku)
                    }
                } = item;
                const selectedVariant = variants[configurableVariantIndex] || product;
                const { id: variantID } = selectedVariant;
                const IDsForPurchaseEvent = isPurchaseEvent ? {
                    increment_ID_Con: configurableID,
                    increment_ID_Sim: ProductHelper.getVariantID(configurableID, variantID, true)
                } : {};

                const returnData = {
                    ...ProductHelper.getItemData(item),
                    ...(isPurchaseEvent && isGA4 ? {} : this.getItemListData(item.product)), // Should not be included for GA4 purchase events
                    quantity: ProductHelper.getQuantity(item),
                    ...IDsForPurchaseEvent
                };

                if (isPurchaseEvent && isGA4) {
                    const {
                        price_range: {
                            minimum_price: {
                                regular_price: { value: regularPrice } = { value: 0 },
                                discount: { amount_off: discountAmount } = { discount_off: 0 } // This is the discount from cart rules, not coupons
                            }
                        }
                    } = selectedVariant;

                    const {
                        attributes: {
                            department: { attribute_value: departmentValue } = { attribute_value: undefined },
                            business_unit: { attribute_value: businessUnitValue } = { attribute_value: undefined },
                            collection: { attribute_value: collectionValue } = { attribute_value: undefined }
                        },
                        sku: variantSku
                    } = selectedVariant;

                    const {
                        product: {
                            attributes: {
                                age_group_global: { attribute_options: ageGroupGlobalOptions = {}, attribute_value: ageGroupGlobalAttributeValue = undefined } = {},
                                sportType: { attribute_options: sportTypeOptions = {}, attribute_value: sportTypeAttributeValue = undefined } = {},
                                product_division: { attribute_options: productDivisionOptions = {}, attribute_value: productDivisionAttributeValue = undefined } = {},
                                franchise: { attribute_options: franchiseOptions = {}, attribute_value: franchiseAttributeValue = undefined } = {}
                            } = {},
                            attributes,
                            categoriesForGA
                        } = {}
                    } = item;

                    const ageGroupGlobalValue = ageGroupGlobalOptions[ageGroupGlobalAttributeValue]?.label || undefined;
                    const sportValue = sportTypeOptions[sportTypeAttributeValue]?.label || undefined;
                    const productDivisionValue = productDivisionOptions[productDivisionAttributeValue]?.label || undefined;
                    const franchiseValue = franchiseOptions[franchiseAttributeValue]?.label || undefined;
                    const sizeValue = ProductHelper.getAttribute(selectedVariant, attributes, SIZE_ATTRIBUTE);

                    // Find the product in totals.items
                    const productInTotals = totals?.items?.find((totalsItem) => totalsItem.sku === sku);
                    const discountFromCoupons = Math.abs(productInTotals?.prices?.total_item_discount?.value) || 0;

                    const moreReturnData = {
                        coupon: couponCode,
                        full_price: regularPrice,
                        sale_discount: discountAmount,
                        discount: discountFromCoupons, // Overwrite value coming from above
                        size: /^\d+$/.test(sizeValue) ? `a${sizeValue}` : sizeValue,
                        discounted: discountAmount > 0 || discountFromCoupons > 0,
                        item_ean: variantSku,
                        color: ProductHelper.getAttribute(selectedVariant, attributes, COLOR_ATTRIBUTE),
                        style_number: sku,
                        department: departmentValue,
                        business_unit: businessUnitValue,
                        sport: sportValue,
                        gender: ProductHelper.getAttribute(selectedVariant, attributes, GENDER_ATTRIBUTE),
                        line_name: sportValue,
                        collection: collectionValue,
                        division: productDivisionValue,
                        franchise: franchiseValue,
                        age_group: ageGroupGlobalValue,
                        item_variant: 'NOT MANDATORY: Magento will not send this field'
                    };

                    // Do breadcrumb magic
                    // eslint-disable-next-line no-magic-numbers, fp/no-let
                    for (let i = 0; i < 5; i++) {
                        moreReturnData[`item_category${i === 0 ? '' : i + 1}`] = categoriesForGA[i] ? categoriesForGA[i].name : undefined;
                    }

                    return {
                        ...returnData,
                        ...moreReturnData
                    };
                }

                return returnData;
            }
        );
    }
}

export default BaseEventEvent;

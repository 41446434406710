/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { CmsBlock } from 'Route/CartPage/CartPage.component';

import BigScreenNewSessionPopup from '../../../component/BigScreenNewSessionPopup';
import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';
import QrPopup from '../../../component/QrPopup';
import SalesAssociate from '../../../component/SalesAssociate';
import { BIG_SCREEN_HOMEPAGE } from '../../../route/QrPage/QrPage.config';

import '../../../component/CartPage/CartPage.extended.style';

/**
 * Cart Page plugin
 */
export class CartPageComponentPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        secureBaseUrl: PropTypes.string.isRequired,
        hideNotification: PropTypes.func.isRequired
    });

    state = (originalMember) => ({
        ...originalMember,
        isSalesAssociateExpanded: false
    });

    expandSalesAssociateContent = (instance) => {
        instance.setState(({ isSalesAssociateExpanded }) => ({
            isSalesAssociateExpanded: !isSalesAssociateExpanded
        }));
    };

    renderTotals = (args, callback, instance) => (
        /** ID is required to measure the element`s height in ExpandableContent.component.js */
        <article id="CartPageSummary" block="CartPage" elem="Summary">
            <h4 block="CartPage" elem="SummaryHeading">{ __('Order Summary') }</h4>
            { instance.renderTotalDetails() }
            { instance.renderTotal() }
            { instance.renderDiscountCode() }
            { this.renderSaleAssociateId(instance) }
            { instance.renderButtons() }
        </article>
    );

    renderSaleAssociateId(instance) {
        const {
            totals: { sales_associate_id }
        } = instance.props;
        const { isSalesAssociateExpanded } = instance.state;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return false;
        }

        return (
            <div
              block="CartPage"
              elem="SalesAssociate"
              mix={ { block: 'CartPage', elem: 'DiscountCode' } }
              mods={ { isSalesAssociateExpanded } }
            >
              <div
                block="CartPage"
                elem="SalesAssociate"
                mix={ { block: 'CartPage', elem: 'Discount' } }
              >
                  <span>{ __('Sales Associate ID') }</span>
                  <button
                    block="CartPage"
                    elem="SalesAssociateButton"
                    mods={ { isSalesAssociateExpanded } }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={ () => this.expandSalesAssociateContent(instance) }
                  >
                      <span>{ __('Sales Associate ID') }</span>
                  </button>
              </div>
              <SalesAssociate salesAssociate={ sales_associate_id } />
            </div>
        );
    }

    renderNewSessionPopup() {
        return (
            <BigScreenNewSessionPopup />
        );
    }

    renderQrPopup() {
        return (
            <QrPopup />
        );
    }

    render = (args, callback, instance) => {
        const { cart_content: { cart_cms } = {} } = window.contentConfiguration;
        const { totals: { items }, isCartItemLoading } = instance.props;

        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);
        const isCartEmpty = !!(!items || items.length < 1);

        return (
            <main block="CartPage" aria-label="Cart Page">
                <div block="CartPage" elem="Header">
                    <ContentWrapper
                      wrapperMix={ { block: 'PageHeader' } }
                      label="Cart Page Header"
                    >
                        { instance.renderHeading() }
                        <Link
                          block="CartPage"
                          elem="ContinueShopping"
                          to={ isBigScreen ? BIG_SCREEN_HOMEPAGE : '/' }
                        >
                            { __('Continue shopping') }
                        </Link>
                    </ContentWrapper>
                </div>
                <ContentWrapper
                  wrapperMix={ { block: 'CartPage', elem: 'Wrapper', mods: { isCartEmpty } } }
                  label="Cart page details"
                >
                    <div block="CartPage" elem="Static" mods={ { isCartEmpty } }>
                        <Loader isLoading={ isCartItemLoading } />
                        { instance.renderCartItems() }
                    </div>
                    <div block="CartPage" elem="Floating">
                        { (items && items.length > 0) && instance.renderTotals() }
                    </div>
                </ContentWrapper>
                <div id="seg-home-recommendation" />
                <div block="CartPage" elem="CartAdvantages">
                    <Suspense fallback={ null }>
                        <CmsBlock identifier={ cart_cms || 'cart-footer' } />
                    </Suspense>
                </div>
                { this.renderNewSessionPopup() }
                { this.renderQrPopup() }
            </main>
        );
    };
}

const {
    propTypes,
    state,
    renderTotals,
    render
} = new CartPageComponentPlugin();

export const config = {
    'Scandipwa/Route/CartPage/Component': {
        'static-member': {
            propTypes
        },
        'member-property': {
            state
        },
        'member-function': {
            renderTotals,
            render
        }
    }
};

export default config;

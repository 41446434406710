/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorHandler from 'Component/ErrorHandler';
import Loader from 'Component/Loader';
import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    CACHE_NAME,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    COOKIE_POPUP,
    FOOTER,
    HEADER,
    HOME,
    LOGIN,
    MY_ACCOUNT,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    NOTIFICATIONS,
    SEARCH,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES
} from 'Component/Router/Router.config';
import NoMatchHandler from 'Route/NoMatchHandler';
import UrlRewrites from 'Route/UrlRewrites';
import RenderWhenVisible from 'SourceComponent/RenderWhenVisible';
import { Router as SourceRouterComponent } from 'SourceComponent/Router/Router.component';

import './Router.style';

export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
export const ConfirmAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/ConfirmAccountPage'));
export const CookiePopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/CookiePopup'));
export const Footer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */ 'Component/Footer'));
export const Header = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Header'));
export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'));
export const MenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "menu" */ 'Route/MenuPage'));
export const LoginAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/LoginAccount'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const NavigationTabs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/NavigationTabs'));
export const NewVersionPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NewVersionPopup'));
export const NotificationList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notifications" */ 'Component/NotificationList'));
export const PasswordChangePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/PasswordChangePage'));
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/SearchPage'));
export const SegmentifySearch = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "segmentify-search" */ 'Route/SegmentifySearch'));
export const StyleGuide = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "style-guide" */ 'Route/StyleGuide'));
export const WishlistPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "wishlist" */ 'Route/WishlistPage'));
export const SubscriptionBlockSuccessCMS = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/SubscriptionBlockSuccessCMS'));

/** @namespace Scandipwa/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace Scandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouterComponent {
    static propTypes = {
        ...SourceRouterComponent.propTypes,
        isLoading: PropTypes.bool.isRequired,
        isNotificationListVisible: PropTypes.bool.isRequired,
        isSiteBaseHasLoaded: PropTypes.bool.isRequired,
        baseLinkUrl: PropTypes.string.isRequired
    };

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <Header { ...this.props } />,
            position: 10,
            name: HEADER
        },
        {
            component: <NavigationTabs { ...this.props } />,
            position: 20,
            name: NAVIGATION_TABS
        },
        {
            component: <CookiePopup />,
            position: 40,
            name: COOKIE_POPUP
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10,
            name: HOME
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25,
            name: SEARCH
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40,
            name: CMS_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50,
            name: CART
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55,
            name: CHECKOUT
        },
        {
            component: <Route path={ withStoreRegex('/:account*/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60,
            name: CHANGE_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62,
            name: LOGIN
        },
        {
            component: <Route path={ withStoreRegex('/:account*/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65
        },
        {
            component: <Route path={ withStoreRegex('/customer/account') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 70,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/forgot-password') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 71
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80
        },
        {
            component: <Route path={ withStoreRegex('/segmentifysearch') } render={ (props) => <SegmentifySearch { ...props } /> } />,
            position: 90
        },
        {
            component: <Route path={ withStoreRegex('/wishlist') } render={ (props) => <WishlistPage { ...props } /> } />,
            position: 105
        },
        {
            component: <Route path={ withStoreRegex('/:subscriptionblockform/complete') } render={ (props) => <SubscriptionBlockSuccessCMS { ...props } /> } />,
            position: 120
        },
        // If you need style guide, please enable it on your local
        // {
        //     component: <Route path={ withStoreRegex('/style/guide') } render={ (props) => <StyleGuide { ...props } /> } />,
        //     position: 999
        // },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000,
            name: URL_REWRITES
        }
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: this.renderNotifications(),
            position: 10,
            renderIfPropsHas: 'isNotificationListVisible'
        },
        {
            component: this.renderFooter(),
            position: 20,
            name: FOOTER
        }
    ];

    [NOTIFICATIONS] = [
        {
            component: this.renderNotifications(),
            position: 10,
            renderIfPropsHas: 'isNotificationListVisible'
        }
    ];

    componentDidMount() {
        if (navigator.platform === 'MacIntel') {
            document.body.classList.add('mac');
        }
    }

    getSortedItems(type) {
        return this[type].sort(
            (a, b) => a.position - b.position
        ).filter(
            (entry) => {
                if (entry.renderIfPropsHas) {
                    const { [entry.renderIfPropsHas]: renderIfPropsHas } = this.props;

                    return renderIfPropsHas;
                }

                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');
                    return false;
                }

                return true;
            }
        );
    }

    clearCache = () => (
        Promise.all([
            // clear all caches
            caches.keys().then(
                /** @namespace Scandipwa/Component/Router/Component/RouterComponent/Promise/all/keys/then */
                (cacheNames) => (
                    Promise.all(
                        cacheNames.map((cacheName) => caches.delete(cacheName))
                    )
                )
            ),
            // clear indexed DB
            new Promise((resolve) => {
                if (!indexedDB) {
                    resolve();
                }

                const request = indexedDB.deleteDatabase(CACHE_NAME);
                request.onerror = () => resolve();
                request.onsuccess = () => resolve();
            })
        ])
    );

    handleErrorReset = () => {
        const { baseLinkUrl } = this.props;

        this.clearCache();
        this.setState({ hasError: false });
        window.location.href = baseLinkUrl;
    };

    renderFooterPlaceholder() {
        return (
            <div block="Router" elem="FooterPlaceholder" />
        );
    }

    renderFooter() {
        return (
            <RenderWhenVisible fallback={ this.renderFooterPlaceholder }>
                <Suspense fallback={ null }>
                    <Footer />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderNotifications() {
        return (
            <NotificationList />
        );
    }

    renderMainItems() {
        const { isLoading } = this.props;

        return (
            <NoMatchHandler
              isScrollEnabled={ false }
            >
                <main style={ {
                    position: 'fixed',
                    top: '50%',
                    right: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '2'
                } }
                >
                    <Loader isLoading={ isLoading } />
                </main>
                <Switch>
                    { this.renderComponentsOfType(SWITCH_ITEMS_TYPE) }
                </Switch>
            </NoMatchHandler>
        );
    }

    renderFallbackPage(showLoader = false) {
        return (
            <main style={ { height: '100vh' } }>
                { showLoader && <Loader isLoading /> }
            </main>
        );
    }

    renderDefaultRouterContent() {
        const {
            isSiteBaseHasLoaded,
            isOnlyMainItems,
            setBigOfflineNotice
        } = this.props;

        if (isOnlyMainItems) {
            return this.renderMainItems();
        }

        return (
            <ErrorHandler setBigOfflineNotice={ setBigOfflineNotice }>
                    <div block="Router" elem="MainItems">
                        { this.renderMainItems() }
                    </div>
                    { isSiteBaseHasLoaded && this.renderComponentsOfType(AFTER_ITEMS_TYPE) }
                    { window.location.pathname === '/cart' && this.renderComponentsOfType(NOTIFICATIONS) }
            </ErrorHandler>
        );
    }

    renderRouterContent() {
        const { hasError } = this.state;

        if (hasError) {
            return this.renderErrorRouterContent();
        }

        return this.renderDefaultRouterContent();
    }
}

export default RouterComponent;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { validate } from 'Util/Validator';

import { FIELD_TYPE } from './Field.config';

/** @namespace Scandipwa/Component/Field/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    minLength: state.ConfigReducer.telephone_minlength,
    prefix: state.ConfigReducer.telephone_prefix,
    standardMask: state.ConfigReducer.telephone_standard,
    exceptionMasks: state.ConfigReducer.telephone_exceptionmasks
});

/** @namespace Scandipwa/Component/Field/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Scandipwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        countryId: PropTypes.string,
        placeholder: PropTypes.string,
        isRequired: PropTypes.bool,
        showCheckMark: PropTypes.bool,
        isDatePicker: PropTypes.bool,
        noSort: PropTypes.bool
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        countryId: 'US',
        placeholder: '',
        isRequired: false,
        showCheckMark: false,
        isDatePicker: false,
        // Used by FieldZip
        setIsValidByCustomRules: () => {}
    };

    containerProps() {
        const {
            countryId,
            id,
            innerLabel,
            isLabelInner,
            name,
            regionId,
            setIsValidByCustomRules,
            isQuestionMarkNeeded,
            showCheckMark,
            isDatePicker,
            noSort
        } = this.props;

        return {
            ...super.containerProps(),
            countryId,
            id,
            innerLabel,
            isLabelInner,
            name,
            regionId,
            showCheckMark,
            setIsValidByCustomRules,
            isQuestionMarkNeeded,
            isDatePicker,
            noSort
        };
    }

    handleShowError(currentLength, minLength) {
        const { validationRule, type } = this.props;
        const { showLengthError } = this.state;

        if (type === FIELD_TYPE.textarea || type === FIELD_TYPE.text) {
            validationRule.range = { ...validationRule.range, showLengthError };
        }

        if (type === FIELD_TYPE.telephone) {
            validationRule.telephone = { currentLength, minLength };
        }

        return validationRule;
    }

    validate(data) {
        const {
            attr: { name } = {},
            prefix,
            type,
            validationRule: { range: { max: maxValidLength = 0 } = {} },
            minLength
        } = this.props;
        const { showLengthError } = this.state;
        const value = type === FIELD_TYPE.checkbox || type === FIELD_TYPE.radio
            ? !!this.fieldRef.checked
            : this.fieldRef.value;

        const newValue = type === FIELD_TYPE.telephone
            ? value.replace(/[()_-\s]/g, '').substr(prefix.length).length
            : value;

        const newValidRule = this.handleShowError(newValue, minLength);

        const response = validate(type === FIELD_TYPE.file ? newValue.toLowerCase() : newValue, newValidRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && response !== true) {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (maxValidLength && value.length > maxValidLength && !showLengthError) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        this.setState({ validationResponse: output });

        this.propagateValidationState(output);

        return output;
    }

    propagateValidationState(output) {
        const { setIsValid } = this.props;

        if (setIsValid && typeof (setIsValid) === 'function') {
            setIsValid(output === true);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldContainer);

/* eslint-disable no-magic-numbers,max-lines */
/* eslint-disable quote-props,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PAYPAL_EXPRESS } from '@scandipwa/paypal/src/component/PayPal/PayPal.config';

import { ORDER_ID_KEY, PAYMENT_METHOD_CODE } from 'Route/Checkout/Checkout.config';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { getIndexedProduct } from 'Util/Product';

import { PLP_DATA_OBJECT }
from '../../component/GoogleTagManager/events/PageDataEvent/Handlers/ProductsPageDataEvent.handler';
// eslint-disable-next-line max-len
import { CHECKOUT_PRODUCTS_TYPE, ORDER_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import Event, {
    EVENT_GTM_ORDER_CONFIRMATION,
    EVENT_GTM_PAGE_DATA,
    EVENT_GTM_PURCHASE,
    EVENT_GTM_PURCHASE_GA4,
    EVENT_GTM_PWA_PURCHASE
} from '../../util/Event';
import { PAYMENT_METHOD } from './AddPaymentInfo.plugin';

export const PURCHASE_EVENT_TRIGGER_DELAY = 3000;

export const dispatchPurchaseEvent = (instance, data) => {
    const { purchaseEventDispatched } = instance.state;

    if (!purchaseEventDispatched) {
        instance.setState({ purchaseEventDispatched: true });

        setTimeout(() => {
            Event.dispatch(
                EVENT_GTM_PURCHASE_GA4,
                data
            );

            Event.dispatch(
                EVENT_GTM_PURCHASE,
                data
            );

            // This line breaks Kueski, is it needed?
            // BrowserDatabase.deleteItem(PAYMENT_METHOD);
        }, PURCHASE_EVENT_TRIGGER_DELAY);
    }
};

export const setDetailsStep = (args, callback, instance) => {
    const kueskiTotals = BrowserDatabase.getItem('kueski_cart_totals');

    if (kueskiTotals) {
        window.cart_totals = kueskiTotals;
        BrowserDatabase.deleteItem('kueski_cart_totals');
    }

    const [orderIncrementId, status_label] = args;
    const { cash_on_delivery_fee = 0, email } = instance.state;
    const { shippingFields: { firstname } } = instance.props;
    const cashOnDeliveryFee = cash_on_delivery_fee || 0; // in case its value is null
    const payment_type = BrowserDatabase.getItem(PAYMENT_METHOD) || '';
    const totals = kueskiTotals || window.cart_totals || BrowserDatabase.getItem(CART_TOTALS);
    const { order_id: kueskiOrderIncrementId, items = [] } = totals || {};

    BrowserDatabase.setItem(kueskiOrderIncrementId || orderIncrementId, ORDER_ID_KEY);

    Event.dispatch(
        EVENT_GTM_PAGE_DATA,
        { type: CHECKOUT_PRODUCTS_TYPE }
    );

    dispatchPurchaseEvent(
        instance,
        {
            orderID: kueskiOrderIncrementId || orderIncrementId,
            totals: {
                ...totals, items, payment_type, cashOnDeliveryFee, email, firstname
            }
        }
    );

    Event.dispatch(
        EVENT_GTM_PAGE_DATA,
        {
            type: ORDER_TYPE, id: orderIncrementId, status: status_label, mods: { step: 'details' }
        }
    );

    Event.dispatch(
        EVENT_GTM_ORDER_CONFIRMATION,
        { orderID: orderIncrementId, totals: { ...totals, items } }
    );

    return callback(...args);
};

/**
 * For Kueski and Aplazo
 */
export const aroundSetDetailsStepCopy = (args, callback) => {
    const [, orderID] = args;

    BrowserDatabase.setItem(orderID, ORDER_ID_KEY);

    return callback(...args);
};

/**
 * This is overwritten to not store the new cartTotals, coming from the BE, in the LocalStorage on the checkout/billing page
 * because after placing an order, the CART_TOTALS item will have empty values(prices, items, etc.)
 * but we need the CART_TOTALS to contain the values of the order, so we can use them in the dataLayer purchase event on the checkout/success page
 *
 * @namespace Scandipwa/Gtm/Plugin/Purchase/Plugin/updateCartTotals
 */
export const updateCartTotals = (args) => {
    const [{ cartData: { items = [], shipping_addresses = {}, ...rest } = {} }] = args;
    const { location: { pathname } } = history;

    const cartTotals = {
        ...rest,
        items: [],
        shipping_addresses: {}
    };

    if (items.length) {
        const normalizedItemsProduct = items.map((item) => {
            const {
                // eslint-disable-next-line no-unused-vars
                variants,
                bundle_customizable_options,
                configurable_customizable_options,
                downloadable_customizable_options,
                virtual_customizable_options,
                simple_customizable_options,
                ...normalizedItem
            } = item;

            normalizedItem.product = getIndexedProduct(item.product, item.sku);

            normalizedItem.customizable_options = bundle_customizable_options
                || configurable_customizable_options
                || downloadable_customizable_options
                || virtual_customizable_options
                || simple_customizable_options
                || [];

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
    }

    cartTotals.shipping_addresses = shipping_addresses[0] || {};

    if ((!pathname.includes('checkout/billing')
            && !pathname.includes('aplazo')
            && !pathname.includes('kueski'))
        || items.length) {
        BrowserDatabase.setItem(
            cartTotals,
            CART_TOTALS
        );
    }

    return { cartTotals, isLoading: false };
};

export const dispatchPWAPurchaseEvent = () => {
    // eslint-disable-next-line fp/no-let
    let timesRun = 0;

    const intervalId = setInterval(() => {
        timesRun++;

        // Stop loop after 5 seconds
        if (timesRun === 25) {
            clearInterval(intervalId);
        }

        if (
            window?.pageData?.order?.id
            && window?.pageData?.navigation?.pageType === 'order confirmation'
        ) {
            Event.dispatch(EVENT_GTM_PWA_PURCHASE);
            clearInterval(intervalId);
        }
    }, 200);
};

export const componentDidMount = (args, callback, instance) => {
    instance.setState({
        pwaPurchaseDispatched: false,
        purchaseEventDispatched: false
    });

    return callback(...args);
};

export const componentWillUnmount = (args, callback) => {
    if (window.pageData) {
        // eslint-disable-next-line fp/no-delete
        delete window.pageData[PLP_DATA_OBJECT];
    }

    return callback(...args);
};

export const aroundComponentDidUpdate = (args, callback = () => {}, instance) => {
    const { location: { pathname } } = instance.props;
    const isSuccessPage = pathname.includes('success');

    if (isSuccessPage) {
        const kueskiTotals = BrowserDatabase.getItem('kueski_cart_totals');

        if (kueskiTotals) {
            window.cart_totals = kueskiTotals;
            BrowserDatabase.deleteItem('kueski_cart_totals');
        }

        const { pwaPurchaseDispatched, cash_on_delivery_fee = 0 } = instance.state;
        const cashOnDeliveryFee = cash_on_delivery_fee || 0; // in case its value is null
        const orderId = BrowserDatabase.getItem(ORDER_ID_KEY);
        const payment_type = BrowserDatabase.getItem(PAYMENT_METHOD) || '';
        const totals = window.cart_totals || BrowserDatabase.getItem(CART_TOTALS);
        const { order_id: kueskiOrderIncrementId, items = [] } = totals || {};

        dispatchPurchaseEvent(
            instance,
            {
                orderID: kueskiOrderIncrementId || orderId,
                totals: {
                    ...totals,
                    items,
                    payment_type,
                    cashOnDeliveryFee
                }
            }
        );

        // Notifying GTM as setDetailsStep function is not called for PayPal Express and Adyen 3DS payment methods
        if (
            (payment_type === PAYPAL_EXPRESS || payment_type === 'adyen_cc')
        ) {
            Event.dispatch(
                EVENT_GTM_ORDER_CONFIRMATION,
                { orderID: orderId, totals: { ...totals, items } }
            );
        }

        if (!pwaPurchaseDispatched) {
            dispatchPWAPurchaseEvent(instance);

            instance.setState({ pwaPurchaseDispatched: true });
        }
    }

    return callback(...args);
};

export const resetCart = (args, callback = () => {}, instance) => {
    BrowserDatabase.deleteItem(PAYMENT_METHOD_CODE);

    return callback.apply(instance, args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            'componentDidUpdate': aroundComponentDidUpdate,
            setDetailsStep,
            componentWillUnmount,
            componentDidMount
        }
    },
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            resetCart
        }
    },
    'Aplazopaygraphql/Scandipwa/App/Plugin/CheckoutContainer/Plugin': {
        'member-function': {
            'setAplazoDetailsStep': aroundSetDetailsStepCopy
        }
    },
    'Scandiweb/KueskiPay/Plugin/CheckoutContainer/Plugin': {
        'member-function': {
            'setKueskiDetailsStep': aroundSetDetailsStepCopy
        }
    },
    'Store/Cart/Reducer/updateCartTotals': {
        function: updateCartTotals
    }
};

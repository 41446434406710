/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { CheckoutQuery as SourceQuery } from 'SourceQuery/Checkout.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Checkout/Query */
export class CheckoutQuery extends SourceQuery {
    _getOrderField() {
        return new Field('order')
            .addFieldList(['order_id', this._getOxxoAdditionalData()]);
    }

    getAdyenActionField() {
        return new Field('action')
            .addFieldList([
                'paymentData',
                'paymentMethodType',
                'authorisationToken',
                'subtype',
                'token',
                'type',
                'url',
                this.getAdyenDataField(),
                'method'
            ]);
    }

    getAdyenDataField() {
        return new Field('data')
            .addFieldList([
                'MD',
                'PaReq',
                'TermUrl'
            ]);
    }

    _getOxxoAdditionalData() {
        return new Field('oxxo_additional_data')
            .addFieldList([
                'reference',
                'expires_at',
                'barcode_url'
            ])
            .setAlias('oxxoAdditionalData');
    }

    getSubShippingMethodFields() {
        return new Field('sub_methods')
            .addFieldList([
                'name',
                'location_code',
                'city',
                'region',
                'street',
                'postcode'
            ]);
    }

    _getEstimatedShippingFields() {
        return [
            ...super._getEstimatedShippingFields(),
            this.getSubShippingMethodFields()
        ];
    }

    getEstimateShippingCosts(address, guestCartId) {
        const mutation = new Field('estimateShippingCosts')
            .addArgument('address', 'EstimateShippingCostsAddress!', address)
            .addFieldList(this._getEstimatedShippingFields());

        this._addGuestCartId(guestCartId, mutation);

        return mutation;
    }

    getPlaceOrderMutation(guestCartId) {
        const [, ga = ''] = document.cookie.match(/_ga=([^;]+);/) || [];

        // varnish removes _ga cookie from the request.
        // create a session cookie with the different name.
        // this code is placed here to execute right before the order place request.
        document.cookie = `puma_cid=${ga};path=/`;

        return super.getPlaceOrderMutation(guestCartId);
    }
}

export default new CheckoutQuery();

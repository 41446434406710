/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_Subscription
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Subscription/Query */
export class SubscriptionQuery {
    getSubscriptionEmailQuery(email) {
        return new Field('addEmailToSubscription')
            .addArgument('email', 'String!', email);
    }

    getSubscriptionCmsQuery(
        email,
        firstName,
        lastName,
        acceptTerms,
        emarsysCampaignName,
        emarsysCampaignLead,
        interest_categorias_lifestyle,
        interest_categorias_motorsport,
        interest_categorias_futbol,
        interest_categorias_basketball,
        interest_categorias_running,
        interest_categorias_training
    ) {
        return new Field('SubscribeFromCMSBlock')
            .addArgument('email', 'String!', email)
            .addArgument('firstName', 'String!', firstName)
            .addArgument('lastName', 'String!', lastName)
            .addArgument('acceptTerms', 'Boolean!', acceptTerms)
            .addArgument('emarsysCampaignName', 'String!', emarsysCampaignName)
            .addArgument('emarsysCampaignLead', 'String!', emarsysCampaignLead)
            .addArgument('interest_categorias_lifestyle', 'Boolean!', interest_categorias_lifestyle)
            .addArgument('interest_categorias_motorsport', 'Boolean!', interest_categorias_motorsport)
            .addArgument('interest_categorias_futbol', 'Boolean!', interest_categorias_futbol)
            .addArgument('interest_categorias_basketball', 'Boolean!', interest_categorias_basketball)
            .addArgument('interest_categorias_running', 'Boolean!', interest_categorias_running)
            .addArgument('interest_categorias_training', 'Boolean!', interest_categorias_training);
    }
}

export default new SubscriptionQuery();
